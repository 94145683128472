<template>
<div class="view-home">
  <div class="container questions-wrap pt-5" v-if="!loading">
    <div class="question-title">
      <h3 class="text-primary">{{ $t('rules.title') }}</h3>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <div class="question-content">
          {{ $t('rules.not-allowed') }}
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12 d-flex flex-column align-items-center test-rule">
                <img height="170" src="/images/rule1.svg" alt="">
                <img class="pb-4 pt-4" width="70" src="/images/no.png" alt="">
                <div>{{ $t('rules.rule-1') }}</div>
            </div>
            <div class="col-lg-4 col-md-12 d-flex flex-column align-items-center test-rule">
                <img height="170" src="/images/rule2.svg" alt="">
                <img class="pb-4 pt-4" width="70" src="/images/no.png" alt="">
                <div>{{ $t('rules.rule-2') }}</div>
            </div>
            <div class="col-lg-4 col-md-12 d-flex flex-column align-items-center test-rule">
                <img height="170" src="/images/rule3.svg" alt="">
                <img class="pb-4 pt-4" width="70" src="/images/no.png" alt="">
                <div>{{ $t('rules.rule-3') }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 d-flex flex-row test-rule justify-content-center">
                <img height="284" src="/images/rule4.svg" alt="">
                <img class="mt-auto tick" width="70" src="/images/yes.png" alt="">
            </div>
        </div>
      </div>
    </div>
    <div class="row my-5">
      <div class="mx-auto">
        <a @click="next" class="btn btn-primary">{{ $t('rules.next-button') }}</a>
<!--        <router-link to="/identification" tag="a" class="btn btn-primary">Следующий шаг</router-link>-->
      </div>
    </div>
  </div>
  <Loader v-else/>
</div>
</template>
<script>
import Loader from '../../components/Loader';
import Vue from "vue";
export default {
  name: "Enter",
  data() {
    return {
      test_lang: 'ru',
      loading: true,
    }
  },
  components: {
    Loader
  },
  methods: {
    next(){
      // let test_lang = sessionStorage.getItem('test_lang')
      // if (test_lang) {
      //   this.test_lang = test_lang;
      //   this.$i18n.locale = test_lang
      // } else {
      //     this.$i18n.locale = 'ru';
      // }
      // this.$router.push({ name: 'quiz-about' })
    }
  },
  mounted() {
    this.loading = true
      this.$http.get(API_ROOT + '/api/quiz/start/' + this.$route.params.id + '?lang=' + this.$route.params.lang).then((res) => {
          if (res.body) {
            this.test_lang = res.body.test_lang
            sessionStorage.removeItem('saved-answers');
            sessionStorage.removeItem('right-answers');
            // sessionStorage.setItem('quiz-token', this.$route.params.token);
            sessionStorage.setItem('test_lang', res.body.test_lang);
            this.$i18n.locale = res.body.test_lang;
            if (res.body.data) {
              const quizData = res.body.data
              if (quizData.questions) {
                  quizData.questions.forEach(question => {
                      if (question.answer_type_id === 6) {
                          const arr = question.answers[0][`text_${this.test_lang}`].split('{word}')
                          let newStr = ''
                          for (let i = 0; i < arr.length; i++) {
                              newStr += arr[i]
                              if (i < arr.length - 1) {
                                  newStr += `<input type="text" data-model="${i}" />`
                              }
                          }
                          question.answers[0][`text_${this.test_lang}`] = newStr
                      }
                      if (question.answer_type_id === 7) {
                          const arr = question[`text_${this.test_lang}`].split('{word}')
                          const selects = []
                          let text = ''
                          for (let i = 0; i < question.answers.length; i++) {
                              let select = `<select data-model="${i}" class="custom-answer-select">`
                              question.answers[i].map(option => {
                                  select += '<option>' + option[`text_${this.test_lang}`] + '</option>'
                              })
                              select += '</select>'
                              selects.push(select)
                          }
                          for (let i = 0; i < arr.length; i++) {
                              text += arr[i]
                              if (i < arr.length - 1) {
                                  text += selects[i]
                              }
                          }
                          question[`text_${this.test_lang}`] = text
                      }
                  });
              } else {
                  this.$router.push({ name: 'test' })
                  Vue.toastr({
                      message: 'Ошибка!',
                      description: 'Вопросов не найдено',
                      type: 'error'
                  });
                  return
              }
              sessionStorage.setItem('quiz', JSON.stringify(res.body.data));
            }
            this.$i18n.locale = this.test_lang
            this.$router.push({ name: 'quiz-about' })
          }
          // this.loading = false;
    })
    .catch((e) => {
      this.loading = false;
      if (e.status === 404) {
        this.$router.push({ name: 'not-found' })
      }
      console.log(e);
    })
  }
}
</script>
<style>
.test-rule {
    padding: 70px 0;
}
.test-rule div {
    color: #e24c4b;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    width: 220px;
}
.tick {
    margin-left: -55px;
    margin-bottom: -10px;
}
</style>
